import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"


const settings = {
    slidesToShow: 2,
    slidesToScroll: 2,
    dots: true,
}



const Carousel = () => {
return (
    <Slider {...settings} className="m-auto" >
        <StaticImage 
            src="../img/caro0.JPG"
            alt="Img0"
            imgStyle={{borderRadius: "2%"}}
            width={3650}
            height={3000}
        />
        <StaticImage 
            src="../img/caro5.JPG"
            alt="Img5"
            imgStyle={{borderRadius: "2%"}}
            width={3650}
            height={3000}
        />
        <StaticImage 
            src="../img/caro17.JPG"
            alt="Img17"
            imgStyle={{borderRadius: "2%"}}
            width={3650}
            height={3000}
        />
        
        <StaticImage 
            src="../img/caro1.JPG"
            alt="Img1"
            imgStyle={{borderRadius: "2%"}}
            width={3650}
            height={3000}
        />
        <StaticImage 
            src="../img/caro13.JPG"
            alt="Img13"
            imgStyle={{borderRadius: "2%"}}
            width={3650}
            height={3000}
        />
         <StaticImage 
            src="../img/caro7.JPG"
            alt="Img7"
            imgStyle={{borderRadius: "2%"}}
            width={3650}
            height={3000}
        />
        <StaticImage 
            src="../img/caro3.JPG"
            alt="Img3"
            imgStyle={{borderRadius: "2%"}}
            width={3650}
            height={3000}
        />
        <StaticImage 
            src="../img/caro12.JPG"
            alt="Img12"
            imgStyle={{borderRadius: "2%"}}
            width={3650}
            height={3000}
        />
        <StaticImage 
            src="../img/caro14.JPG"
            alt="Img14"
            imgStyle={{borderRadius: "2%"}}
            width={3650}
            height={3000}
        />
        <StaticImage 
            src="../img/caro2.JPG"
            alt="Img2"
            imgStyle={{borderRadius: "2%"}}
            width={3650}
            height={3000}
        />
        <StaticImage 
            src="../img/caro16.JPG"
            alt="Img16"
            imgStyle={{borderRadius: "2%"}}
            width={3650}
            height={3000}
        />
        <StaticImage 
            src="../img/caro8.JPG"
            alt="Img8"
            imgStyle={{borderRadius: "2%"}}
            width={3650}
            height={3000}
        />
        <StaticImage 
            src="../img/caro9.JPG"
            alt="Img9"
            imgStyle={{borderRadius: "2%"}}
            width={3650}
            height={3000}
        />
        <StaticImage 
            src="../img/caro10.JPG"
            alt="Img10"
            imgStyle={{borderRadius: "2%"}}
            width={3650}
            height={3000}
        />
        <StaticImage 
            src="../img/caro15.JPG"
            alt="Img15"
            imgStyle={{borderRadius: "2%"}}
            width={3650}
            height={3000}
        />
        <StaticImage 
            src="../img/caro18.JPG"
            alt="Img18"
            imgStyle={{borderRadius: "2%"}}
            width={3650}
            height={3000}
        />
        <StaticImage 
            src="../img/caro11.JPG"
            alt="Img11"
            imgStyle={{borderRadius: "2%"}}
            width={3650}
            height={3000}
        />
        <StaticImage 
            src="../img/caro19.JPG"
            alt="Img19"
            imgStyle={{borderRadius: "2%"}}
            width={3650}
            height={3000}
        />
        <StaticImage 
            src="../img/caro6.JPG"
            alt="Img6"
            imgStyle={{borderRadius: "2%"}}
            width={3650}
            height={3000}
        />
    </Slider>
    )
}

  
export default Carousel;