import React from 'react';
import Button from '../components/Button';
import Layout from '../components/layout/Layout';
import LandingMain from '../svg/LandingMain';
import Carousel from '../components/Carousel';


const Index = () => (
    <Layout>
      <section className="pt-20 md:pt-20">
        <div className="container mx-auto px-8 lg:flex mt-10">
          <div className="text-center lg:text-left lg:w-1/2">
            <h1 className="text-6xl font-bold leading-none ">
              Layla & Aidan
            </h1>
            <br></br>
            <p className="text-2xl mt-6 font-light">
              Sunday, August 14, 2022
            </p>
            <p className="text-2xl font-light">
              4:00 p.m.
            </p>
            <p className="text-2xl  mt-6 font-light">
              Prospect Park Picnic House
            </p>
            <p className="text-2xl font-light">
              40 West Drive
            </p>
            <p className="text-2xl font-light ">
              Brooklyn, NY 11215
            </p>
            <a href='https://withjoy.com/aidan-and-layla/rsvp' target='_blank'>
              <Button size={'xl'} className="my-20"> 
                RSVP
              </Button>
            </a>
          </div>
          <div className="rounded lg:w-1/2">
              <LandingMain />
          </div>
        </div>
      </section>
      <section id="details" className="lg:py-10"></section>
      <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">

        <h3 className="text-5xl font-semibold">Details</h3>
        
          <section className='text-xl font-light mt-8 inline-block text-left mx-10'>

            <h4 className="text-4xl font-semibold">Schedule of Events:</h4>

              <h3 className='italic text-3xl my-8'>
                Saturday, August 13 
              </h3>

                  <p> <span className='font-bold'>5:00pm: </span>Rehearsal dinner for immediate family</p>
                  
                  <p><span className='font-bold'>8:30-11:30pm: </span>Welcome drinks for 20s/30s crowd</p>

                  <p>Fifth Hammer Brewing Company,</p>

                  <p>10-28 46th Ave, Long Island City, NY</p>
                  
                  <p className='italic'> Dress code: Fun and festive </p>

              <h3 className='italic text-3xl my-8'>
                Sunday, August 14
              </h3>
              
                  <p><span className='font-bold'>4:00pm: </span> Arrive at the&nbsp;
                    <a href='https://www.prospectpark.org/wp-content/uploads/filer_public/37/f8/37f8b570-ca86-4e0f-8a83-776bd0730604/ph_directional_card_spread_july_2015.pdf' target='_blank'>
                    Picnic House</a>
                  </p>
                
                  <p><span className='font-bold'>4:15pm: </span> Ceremony begins</p>
                  
                  <p><span className='font-bold'>5:00-6:00pm: </span>Cocktail hour</p>
                
                  <p><span className='font-bold'>6:00-10:00pm: </span>Dinner and lots of dancing!</p>
                
                  <p className='italic'>Dress code: Cocktail Attire</p>

              <h3 className='italic text-3xl my-8'>
                Monday, August 15 
              </h3>

                  <p><span className='font-bold'>9:00am-12:00pm: </span>Casual breakfast in Brooklyn</p>

                  <p>Open house - drop by whenever</p>

                  <p>Brooklyn bagels and light breakfast fare</p>

                  <p>Rooftop of Fairfield Inn & Suites</p>

                  <p>181 3rd Ave, Brooklyn, NY 11217</p>

                  <p className='italic'>Dress code: Come as you are!</p>

            </section>

      </section>
      <section id="lodging" className="lg:py-10"></section>
      <br></br>
      <br></br>
      <section className="container mx-auto my-10 py-24 bg-gray-200 rounded-lg text-center">
        <h3 className="text-5xl font-semibold">Lodging</h3>
        <p className="mt-8 text-2xl font-light mx-10">
          We have reserved blocks of rooms at local hotels. Please click this link for more information:
        </p>
        <a href='https://drive.google.com/file/d/1PZAkl2_rScaAqdCAcdkMtHSrn_gw68TQ/view' target='_blank'>
              <Button size={'xl'} className="mt-20 select-none"> 
                Lodging Information
              </Button>
        </a>
      </section>
      <section className="py-20 lg:pt-32">
        <br id="photos"></br>
        <br></br>
        <div className="container mx-auto text-center">
          <h2 className="text-5xl lg:text-7xl font-semibold mb-10">Photos</h2>
            <Carousel/>
        </div>
      </section>
      <section id="Gifts" className="lg:py-10"></section>
      <br></br>
      <br></br>
      <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
        <h3 className="text-5xl font-semibold">Gifts</h3>
        <p className="mt-8 text-xl font-light mx-10">
            We know many of you are traveling to celebrate with us, and that is the most special gift. 
            If you were thinking of giving a gift, we would greatly appreciate a contribution to one of the three funds in our online registry:
        </p>
        <a href='https://withjoy.com/aidan-and-layla/registry' target='_blank'>
            <Button size={'xl'} className="mt-20 select-none"> 
              Registry
            </Button>
      </a>
      </section>
    </Layout>
);

export default Index;
