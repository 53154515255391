import React from 'react';
import { StaticImage } from "gatsby-plugin-image"


const LandingMain = () => (
     <StaticImage 
      src="../img/mainalt.png" 
      alt="Landing"
      imgStyle={{borderRadius: "2%"}}
      />
)
  
export default LandingMain;
